import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useTranslations } from "next-intl";
import { useRef, useState } from "react";

import { useBreakpoint } from "~/contexts/breakpoint";
import CrossCountry from "~/types/crossCountry";
import gridUtils from "~/utils/grid-utils";
import imageUtils from "~/utils/image-utils";
import productUtils from "~/utils/product-utils";
import TrackingUtils from "~/utils/tracking-utils";

import Dialog from "../common/dialog";
import Icon from "../common/icon";
import LinkWrapper from "../common/link-wrapper";
import ProductActions from "../common/product-actions";
import NotifyMe from "../notifyMe/notifyMe";
import ProductColorSelector from "./product-color-selector";
import ProductColorsDialog from "./product-colors-dialog";
import ProductLabel from "./product-label";
import ProductPrice from "./product-price";
import ProductRatings from "./product-ratings";
import styles from "./product-tile.module.scss";
import productTileDialogStyles from "./product-tile-dialog.module.scss";

type Props = {
  product: CrossCountry.Product;
  idxInList?: number;
  listScope?: string;
};

export default function ProductTile(props: Props) {
  const t = useTranslations();
  const breakpoint = useBreakpoint();
  const refNotifyMe = useRef<HTMLDivElement>(null);

  const hasChildren = props.product.children && Array.isArray(props.product.children);

  const [currentDialog, setCurrentDialog] = useState<"colors" | "notifyMe" | null>(null);
  const [source, setSource] = useState<CrossCountry.ChildrenProduct | CrossCountry.Product>(
    hasChildren ? props.product.children![0] : props.product
  );

  const isGift = props.product.product_types.includes("GiftCard");
  const productUrl = source.slug + "-" + source.product_id;

  const _trackItem = (
    event: string,
    props: Props,
    variant: CrossCountry.ChildrenProduct,
    payload: Record<string, any> = {}
  ) => {
    TrackingUtils.track(
      TrackingUtils.mapEcommerceEvent(
        event,
        [
          TrackingUtils.mapEcomItem(props.product, variant, {
            index: props.idxInList,
            item_list_name: props.listScope,
          }),
        ],
        payload
      )
    );
  };

  const colorsDialogContent = (
    <>
      {!!currentDialog && hasChildren && (
        <ProductColorsDialog
          products={props.product.children!}
          current={source}
          onChange={() => {
            setCurrentDialog("colors");
          }}
          onSelect={(newProduct) => {
            setSource(newProduct);
            if (newProduct.has_stock) {
              _trackItem("view_item_list", props, newProduct, { user_action: "impression_change_color" });
              setCurrentDialog(null);
            } else {
              setCurrentDialog("notifyMe");
            }
          }}
          showColorList={hasChildren && currentDialog === "colors"}
        />
      )}
      {!source.has_stock && currentDialog === "notifyMe" && (
        <NotifyMe
          ref={refNotifyMe}
          productId={source?.product_id}
          showClose={false}
          className={productTileDialogStyles.notifyMeContainer}
        />
      )}
    </>
  );
  return (
    <div className={styles.container}>
      <Link
        href={`/p/${productUrl}`}
        className={styles.image}
        onClick={() => {
          _trackItem("select_item", props, source);
        }}
      >
        <Image
          priority
          src={source.product_media?.primary_image?.url || imageUtils.transparentPixel}
          alt={props.product.display_value}
          fill
          sizes={gridUtils.imageSizes}
        />
      </Link>
      <div className={styles.label}>
        {source.discounts?.length ? <ProductLabel>{source.discounts[0].PromotionLabel}</ProductLabel> : null}
      </div>
      <div className={styles.description} dangerouslySetInnerHTML={{ __html: props.product.short_description }} />
      <Link href={`/p/${productUrl}`} className={styles.name}>
        <p>{props.product.display_value}</p>
      </Link>
      <div className={styles.ratings}>
        {process.env.NEXT_PUBLIC_POWERREVIEWS_DISPLAY_DATA_FROM_EVA !== "false" &&
          props.product.review_average_score != undefined &&
          props.product.review_total_number != undefined && (
            <ProductRatings
              average={props.product.review_average_score}
              numberOfRatings={props.product.review_total_number}
            />
          )}
      </div>
      <div className={styles.price}>
        <ProductPrice product={source} />
      </div>
      <div className={styles.colorSelector}>
        {hasChildren && (
          <ProductColorSelector product={props.product} current={source} onSelect={() => setCurrentDialog("colors")} />
        )}
      </div>
      <div className={styles.button}>
        {isGift ? (
          <LinkWrapper href={`/p/${productUrl}`} lookAsButton="primary">
            {t("generic.discoverMore")}
          </LinkWrapper>
        ) : (
          <ProductActions
            hasPrice={productUtils.hasValidDisplayPrice(source)}
            productId={source.product_id}
            maxQuantity={source.ecomm_max_items_per_order}
            outOfStock={!source.has_stock}
            onClickNotifyMe={() => {
              if (productUtils.hasValidDisplayPrice(source)) {
                setCurrentDialog("notifyMe");
              }
            }}
            actionsContext={props.listScope}
            trackingData={TrackingUtils.mapEcomItem(props.product, source, {
              item_list_name: props.listScope,
            })}
          />
        )}
      </div>

      {breakpoint === "mobile" && (
        <Dialog
          isOpen={!!currentDialog}
          title={
            <>
              <p className={styles.actionsPaneSubTitle}>{"color" in source ? source.color : ""}</p>
              {hasChildren && <p className={styles.actionsPaneTitle}>{t("generic.product_tiles.all_shades")}</p>}
            </>
          }
          customStyles={productTileDialogStyles}
          onClose={() => setCurrentDialog(null)}
        >
          {colorsDialogContent}
        </Dialog>
      )}
      {breakpoint === "desktop" && (
        <AnimatePresence>
          {!!currentDialog && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className={styles.actionsPaneContainer}
            >
              <button className={styles.actionsPaneButton} onClick={() => setCurrentDialog(null)}>
                <Icon name="chevron-left" width="16" height="16" />
                <p className={styles.actionsPaneTitle}>
                  {hasChildren && currentDialog !== "notifyMe"
                    ? t("generic.product_tiles.all_shades")
                    : t("generic.product_tiles.back")}
                </p>
              </button>
              <div className={styles.actionsPanelContent}>{colorsDialogContent}</div>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </div>
  );
}
